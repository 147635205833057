import React from 'react'
import { AxiosError } from 'axios'
import BaseService from './BaseService'
import { Notification, toast } from 'components/ui'

const StatusNotification = React.forwardRef(({ type, title, msg }, ref) => (
    <Notification ref={ref} closable title={title} type={type} duration={2000}>
        {msg}
    </Notification>
))

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param)
                .then(async (response) => {
                    if (
                        ['post', 'put', 'delete'].includes(
                            param.method.toLowerCase()
                        )
                    ) {
                        const toastId = await toast.push(
                            <StatusNotification
                                type="success"
                                title="Success"
                                msg={response.data?.message}
                            />
                        )
                        setTimeout(() => {
                            toast.remove(toastId)
                        }, 2000)
                    }
                    resolve(response)
                })
                .catch(async (errors) => {
                    if (errors instanceof AxiosError) {
                        const toastId = await toast.push(
                            <StatusNotification
                                type="danger"
                                title={errors.code}
                                msg={errors.message}
                            />
                        )
                        setTimeout(() => {
                            toast.remove(toastId)
                        }, 2000)
                    }
                    reject(errors)
                })
        })
    },
}

export default ApiService
