const y = new Date().getFullYear()

export const rateConfigList = [
    {
        "id": 3,
        "code": "RTE101",
        "description": "Rate percentage commission",
        "value": "0.0",
        "type": "DAILY",
        "status": "SUCCESSFUL",
        "updatedDate": "2023-06-16T00:00:00Z"
    },
    {
        "id": 4,
        "code": "RTE102",
        "description": "Rate fixed commission",
        "value": "40.0",
        "type": "DAILY",
        "status": "SUCCESSFUL",
        "updatedDate": "2023-06-16T00:00:00Z"
    },
    {
        "id": 5,
        "code": "LMT001",
        "description": "Deposit min amount limit",
        "value": "50",
        "type": "USD",
        "status": "SUCCESSFUL",
        "updatedDate": "2023-06-16T00:00:00Z"
    },
    {
        "id": 6,
        "code": "LMT002",
        "description": "Deposit max amount limit",
        "value": "100000.00",
        "type": "USD",
        "status": "SUCCESSFUL",
        "updatedDate": "2023-06-16T00:00:00Z"
    },
    {
        "id": 1,
        "code": "RTE001",
        "description": "USD buy price",
        "value": "3428.0",
        "type": "HOURLY",
        "status": "SUCCESSFUL",
        "updatedDate": "2023-06-19T13:10:01.551201Z"
    },
    {
        "id": 2,
        "code": "RTE002",
        "description": "USD sell price",
        "value": "3448.0",
        "type": "HOURLY",
        "status": "SUCCESSFUL",
        "updatedDate": "2023-06-19T13:10:01.579548Z"
    }
]