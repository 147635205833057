import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'

import { signInUserData } from './data/authData'
import { depositsData, withdrawalsData, depositRequestsData, } from './data/transactionsData'
import {
    rateConfigList
} from './data/rateConfigData'
import { marketData, staffRegistrationsData } from './data/cryptoData'

import { authFakeApi, transactionsFakeApi, rateConfigFakeApi, staffFakeApi } from './fakeApi'

const { apiPrefix } = appConfig

export default function mockServer({ environment = 'test' }) {
    return createServer({
        environment,
        seeds(server) {
            server.db.loadData({
                signInUserData,
                depositsData,
                withdrawalsData,
                depositRequestsData,
                
                rateConfigList,
                staffRegistrationsData,

                marketData,
            })
        },
        routes() {
            this.urlPrefix = ''
            this.namespace = ''
            this.passthrough((request) => {
                let isExternal = request.url.startsWith('http')
                return isExternal
            })
            this.passthrough()

            authFakeApi(this, apiPrefix)
            transactionsFakeApi(this, apiPrefix)
            rateConfigFakeApi(this, apiPrefix)
            staffFakeApi(this, apiPrefix)
        },
    })
}
